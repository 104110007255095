import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Moment from 'react-moment'
import DownloadCard from '../components/downloadCard'
import Layout from '../components/layout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


export const query = graphql`
  query Page($slug: String!) {
    strapiPage(slug: {eq: $slug}) {
      title
      subtitle
      body
      attachments {
        url
        updated_at
        size
        name
        alternativeText
        ext
      }
    }
  }
`

const Page = ({ data }) => {
  const page = data.strapiPage
  console.log('asdf', page)

  return (
    <Layout title={page.title} subtitle={page.subtitle}>
      <section id="beliefs" className="page-section cta">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 mx-auto">
              <div className="cta-inner text-center rounded remark-content">
                <ReactMarkdown
                  source={page.body}
                  escapeHtml={false}
                />
                <p className="text-right">
                  <small>Last updated on <Moment format="MMM Do YYYY">{page.published_at}</Moment></small>
                </p>
              </div>
            </div>
          </div>
        </div>

        {page.attachments && page.attachments.length > 0 ?
          <Container className="mt-5">
            <Row>
              <Col xl={9} className="mx-auto">
                <div className="cta-plain text-center rounded">
                  <h2 className="section-heading mb-4">
                    <span className="section-heading-upper">Attachments</span>
                  </h2>
                  {page.attachments.map((data, index) => (
                    <DownloadCard
                      key={index}
                      name={data.name}
                      downloadName={data.name}
                      updatedAt={data.updated_at}
                      size={data.size}
                      ext={data.ext}
                      publicURL={data.publicURL}
                    />
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
          : <></>}
      </section>
    </Layout>
  )
}

export default Page
