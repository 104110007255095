import React from 'react'
import Moment from 'react-moment'


const DownloadCard = ({ name, downloadName, updated_at, size, ext, publicURL }) => {
  return (
    <div className="row justify-content-left download-container">
      <div className="col-md-10 download-info">
        <div className="date light">
          <Moment format="MMMM YYYY">{updated_at}</Moment>
        </div>
        <div className="title" style={{ display: 'inline-block' }}>
          <h5 className="section-heading text-truncate" style={{ width: '100%' }}>{name}</h5>
        </div>
        <div className="series light">{size} KB</div>
        <div className="speaker neutral">Filetype {ext}</div>
      </div>
      <a className="col-md-2 download-btn" href={publicURL} download={name}>
        <div className="download-icon">
          <i className="fa fa-cloud-download" style={{ color: '#33383a' }}></i>
          <h6>Download</h6>
        </div>
      </a>
    </div>
  )
}

export default DownloadCard
